.card-detail-screen {
    min-height: calc(100vh);
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .back-button {
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .card-detail-container {
    display: flex;
    gap: 30px;
    background-color: transparent;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .card-detail-image {
    flex: 0 0 40%;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card-detail-image img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  @media screen and (max-width: 768px) {
    .card-detail-container {
      flex-direction: column;
    }
  
    .card-detail-image {
      max-width: 100%;
    }
  }
  
  .card-details {
    flex: 1;
  }

  .card-name {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin: 0;
  }

  .card-attribute {
    font-size: 18px;
    color: #555;
    margin: 8px 0 0 0;
  }

  .card-tags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
    margin-left: 0;
  }

  .card-info {
    margin-bottom: 20px;
  }

  .card-info .stats-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
  }

  .stats-table td {
    padding: 10px;
    text-align: center;
    vertical-align: middle;
  }

  .series-row td {
    background-color: #f5f5f5;
    font-weight: bold;
    font-size: 18px;
  }

  .stat-cell .label {
    display: block;
    font-weight: bold;
    color: #555;
    margin-bottom: 5px;
  }

  .stat-cell .value {
    font-size: 16px;
    color: #333;
  }

  .stats-table tr:not(:last-child) td {
    border-bottom: 1px solid #e0e0e0;
  }

  .stats-table tr td:not(:last-child) {
    border-right: 1px solid #e0e0e0;
  }

  /* Remove extra borders to make it feel like a combined table */
  .stats-table td {
    border: none;
  }

  .stats-table tr:not(:last-child) td {
    border-bottom: 1px solid #e0e0e0;
  }

  .stats-table tr td:not(:last-child) {
    border-right: 1px solid #e0e0e0;
  }

  /* Styles for the card-effects container */
  .card-effects {
    margin-top: 20px;
  }

  .card-effects h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #fff; /* Light text color for dark background */
    padding-bottom: 5px;
  }

  .effect-groups {
    margin: 0;
    padding: 0;
  }

  /* Styles for each effect group */
  .effect-group {
    margin-bottom: 10px; /* Less spacing between effects */
    position: relative;
  }

  .effect-group:last-child {
    margin-bottom: 0;
  }

  /* Styles for the effect content box */
  .effect-content {
    background-color: rgba(255, 255, 255, 0.9); /* Light background for readability */
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }

  /* Apply red indicator to RAID effects */
  .effect-group.raid-effect-group .effect-content {
    border-left: 4px solid red; /* Red indicator */
    padding-left: 10px;
  }

  /* Styles for main effects */
  .main-effect p {
    margin: 0;
    font-size: 1em;
    color: #333; /* Dark text color for readability on light background */
  }

  /* Styles for sub-effects */
  .sub-effects {
    margin-top: 5px;
    padding-left: 15px;
  }

  .sub-effects .sub-effect p {
    margin: 0;
    font-size: 0.95em;
    color: #555; /* Slightly lighter text color */
  }

  /* Adjustments for the overall page background */
  body {
    background-color: #1d1c25; /* Dark background */
    color: #fff; /* Light text color */
  }

  /* Adjustments for titles and labels */
  .card-details h3,
  .card-info .label {
    color: #fff; /* Light text color for titles and labels */
  }

  /* Styles for the stats table */
  .stats-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  .stats-table .stat-cell, .stats-table .info-label {
    background-color: rgba(255, 255, 255, 0.9); /* Light background */
    color: #333; /* Dark text color */
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
  }

  .stat-cell .label, .info-label .label {
    color: #555; /* Slightly lighter text color */
  }

  /* Styles for the card name and attribute under the image */
  .card-info-under-image {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
    padding: 10px;
    border-radius: 8px;
    margin-top: 15px;
  }

  .card-info-under-image .card-name {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin: 0;
  }

  .card-info-under-image .card-attribute {
    font-size: 18px;
    color: #555;
    margin: 8px 0 0 0;
  }

  /* Centering card-tags under the name and attribute */
  .card-info-under-image .card-tags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
  }

  .color-tag,
  .type-tag,
  .trigger-tag,
  .raid-tag {
    display: inline-block;
    font-size: 0.8em;
    padding: 2px 5px;
    border-radius: 3px;
    margin-right: 5px;
    color: white;
    font-weight: bold;
  }

  /* Styles for the stats table */
  .stats-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  /* Style for each stat cell */
  .stats-table .stat-cell {
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
    color: #333; /* Dark text color for readability */
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
  }

  /* Optional: Adjust the label and value styles */
  .stat-cell .label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .stat-cell .value {
    display: block;
    font-size: 1.2em;
  }

  /* Style for the series row */
  .stats-table .series-row .info-label {
    background-color: rgba(255, 255, 255, 0.9);
    color: #333;
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
  }

  /* Left-align the effect and trigger effect text */
  .effect-content p {
    text-align: left;
  }

  /* Ensure sub-effect paragraphs are also left-aligned */
  .sub-effects .sub-effect p {
    text-align: left;
  }

  /* Styles for the app-links section */
  .app-links {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .app-link {
    display: inline-flex;
    align-items: center;
    background-color: #ffa500; /* Orange color */
    color: #fff; /* White text */
    padding: 10px 20px;
    border-radius: 8px;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    transition: background-color 0.3s;
  }

  .app-link:hover {
    background-color: #e69500;
  }

  .union-arena-logo {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .app-store-link img {
    width: 150px;
    height: auto;
  }