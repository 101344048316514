.navbar {
  background-color: #1d1c25;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.navbar-logo {
  color: white;
  font-size: 24px;
  text-decoration: none;
  margin-left: 10px;
}

/* Desktop Menu */
.nav-menu-desktop {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.nav-menu-desktop ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 0;
}

.nav-menu-desktop ul li {
  margin: 0 15px;
}

.nav-menu-desktop ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.nav-menu-desktop ul li a .nav-icon {
  margin-right: 8px;
}

.nav-menu-desktop ul li a:hover {
  text-decoration: underline;
}

/* Profile Button */
.profile-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.profile-button.signed-in {
  background-color: #4CAF50;
}

.profile-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Hamburger Menu Icon */
.menu-icon {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 28px;
  cursor: pointer;
  display: none; /* Hidden by default, shown on mobile */
}

/* Subscription Status */
.subscription-status {
  color: white;
  font-size: 16px;
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.subscription-status .subscribed {
  background-color: #4CAF50;
  padding: 8px 16px;
  border-radius: 4px;
}

.subscribe-button {
  background-color: #ff9800;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.subscribe-button:hover {
  background-color: #f57f17;
}

/* Hide mobile menu by default */
.nav-menu-mobile {
  display: none;
}

/* Mobile Menu Styles */
@media screen and (max-width: 768px) {
  /* Hide desktop menu and profile button */
  .nav-menu-desktop,
  .subscription-status,
  .profile-button {
    display: none;
  }

  /* Show hamburger menu icon */
  .menu-icon {
    display: block;
    margin-right: 10px;
  }

  /* Show mobile menu */
  .nav-menu-mobile {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background-color: #1d1c25;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 80px;
    padding-left: 20px;
    overflow-y: auto;
    z-index: 999;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .nav-menu-mobile.active {
    transform: translateX(0);
  }

  .nav-menu-mobile ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .nav-menu-mobile ul li {
    width: 100%;
    margin: 20px 0;
  }

  .nav-menu-mobile ul li a,
  .nav-menu-mobile ul li button {
    font-size: 24px;
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    background: none;
    border: none;
    padding: 10px 0;
    cursor: pointer;
  }

  .nav-menu-mobile ul li a .nav-icon,
  .nav-menu-mobile ul li button .nav-icon {
    margin-right: 15px;
    font-size: 28px;
  }

  .nav-menu-mobile ul li a:hover,
  .nav-menu-mobile ul li button:hover {
    color: #f0f0f0;
  }

  /* Sign Out Button Style */
  .mobile-signout-button {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
  color: #333;
}