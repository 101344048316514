.search-screen {
  width: 100%;
  min-height: calc(100vh - 60px); /* Adjust for navbar height */
  padding-top: 60px;
  box-sizing: border-box;
  overflow-y: auto;
  background: linear-gradient(to bottom, #1d1c25, #131442);
}

.search-navbar {
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  background-color: #242031;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  width: 100%;
  height: 60px;
  padding: 0 20px;
  box-sizing: border-box;
}

.search-input {
  flex-grow: 1;
  padding: 12px 16px;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  margin-right: 10px;
  min-width: 0;
  background-color: #1d1c25;
  color: #D7D7D7;
}

.search-input::placeholder {
  color: #888;
}

.view-dropdown {
  padding: 10px 16px;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  background-color: #1d1c25;
  color: #D7D7D7;
  cursor: pointer;
}

.view-dropdown option {
  background-color: #242031;
  color: #D7D7D7;
}

.filter-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  padding: 15px 20px;
  background-color: #242031;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.filter-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-item label {
  color: #D7D7D7;
  font-weight: 500;
}

.filter-item select,
.filter-item input {
  padding: 10px 16px;
  font-size: 14px;
  border: none;
  border-radius: 30px;
  background-color: #1d1c25;
  color: #D7D7D7;
  cursor: pointer;
}

.filter-item select option {
  background-color: #242031;
  color: #D7D7D7;
}

@media screen and (max-width: 768px) {
  .search-navbar {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .search-input {
    width: 100%;
    margin-bottom: 10px;
  }

  .view-dropdown {
    width: 100%;
    margin-bottom: 10px;
  }

  .filter-bar {
    flex-direction: column;
    align-items: stretch;
  }

  .filter-item {
    width: 100%;
    justify-content: space-between;
  }

  .filter-item select,
  .filter-item input {
    width: 100%;
  }
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  padding: 20px;
  justify-content: center;
}

.card-tile {
  position: relative;
  width: 100%;
  padding-top: 139.64%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card-list {
  padding: 20px;
  overflow-x: auto;
}

.card-list table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 16px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.card-list th,
.card-list td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.2s ease;
  font-size: 16px;
}

.card-list th {
  background-color: #f8f9fa;
  font-weight: 500;
  color: #333;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  padding-right: 25px; /* Add extra padding for sort indicator */
  position: relative; /* For absolute positioning of sort indicator */
}

.card-list th:hover {
  background-color: #e9ecef;
}

/* Style for the sort indicator */
.sort-indicator {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.card-list tr:last-child td {
  border-bottom: none;
}

.card-list tr:nth-child(even) {
  background-color: #f8f9fa;
}

.card-list tr:hover {
  background-color: #f1f3f5;
}

.card-list td {
  color: #333;
  font-weight: 400;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 15px 0;
  background-color: #242031;
}

.pagination button {
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.pagination button:disabled {
  background-color: #444;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #0056b3;
}

.pagination .page-info {
  color: #D7D7D7;
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .pagination {
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
  }

  .pagination .page-info {
    order: -1; /* Move the page info above the buttons */
  }

  .pagination button {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .card-list table {
    font-size: 12px;
  }

  .card-list th,
  .card-list td {
    padding: 5px;
  }
}

@media screen and (min-width: 768px) {
  .card-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media screen and (min-width: 1200px) {
  .card-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.color-tag,
.type-tag,
.trigger-tag,
.raid-tag {
  display: inline-block;
  font-size: 0.8em;
  padding: 2px 5px;
  border-radius: 3px;
  margin-right: 5px;
  color: white;
  font-weight: bold;
}

.color-tag.red { background-color: #ff4136; }
.color-tag.blue { background-color: #0074d9; }
.color-tag.yellow { background-color: #ffdc00; color: black; }
.color-tag.green { background-color: #2ecc40; }
.color-tag.purple { background-color: #b10dc9; }
.color-tag.black { background-color: #111111; }
.color-tag.white { background-color: #dddddd; color: black; }

.type-tag.character { background-color: #FF851B; }
.type-tag.event { background-color: #39CCCC; }
.type-tag.site { background-color: #3D9970; }
.type-tag.action-point { background-color: #85144b; }

.trigger-tag { background-color: #B10DC9; } /* Purple */
.raid-tag { background-color: #FF4136; } /* Red */

.card-full {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.full-card-container {
  display: flex;
  width: calc(50% - 10px);
  max-width: 800px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.full-card-image {
  flex: 0 0 40%;
  max-width: 40%;
}

.full-card-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.card-details {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  /* max-height: 500px; */
}

.card-name {
  margin: 0 0 5px 0;
  font-size: 1.5em;
}

.card-attribute {
  margin: 0 0 10px 0;
  font-size: 0.9em;
  color: #666;
}

.card-tags {
  margin-bottom: 15px;
}

.card-info p {
  margin: 5px 0;
  font-size: 0.9em;
}

.card-effect, .card-trigger-effect {
  margin-top: 15px;
}

.card-effect h3, .card-trigger-effect h3 {
  margin-bottom: 5px;
  font-size: 1em;
}

.card-effect p, .card-trigger-effect p {
  margin: 5px 0;
  font-size: 0.9em;
}

@media screen and (max-width: 1200px) {
  .full-card-container {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .full-card-container {
    flex-direction: column;
  }

  .full-card-image {
    max-width: 100%;
  }

  .card-details {
    max-height: none;
  }
}

.card-stats {
  margin-top: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stat-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
}

.stat-row:last-child {
  margin-bottom: 0;
}

.stat-item {
  flex: 1;
  display: flex;
  align-items: center;
}

.stat-label {
  font-size: 0.85em;
  font-weight: 600;
  color: #666;
  margin-right: 8px;
  text-transform: uppercase;
  width: 80px; /* Adjust this value to ensure alignment */
}

.stat-value {
  font-size: 1.1em;
  font-weight: 700;
  color: #333;
  background-color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  min-width: 30px; /* Adjust this value to ensure alignment */
  text-align: center;
}

.stat-power {
  justify-content: center;
}

.stat-power .stat-value {
  background-color: #007bff;
  color: #fff;
}

.stat-power .stat-value {
  background-color: #007bff;
  color: #fff;
}