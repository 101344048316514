.home {
  background: linear-gradient(to bottom, #1d1c25, #131442);
  position: relative;
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.home-content {
  position: relative;
  z-index: 2; /* Ensure content is above the collage */
  color: #fff;
  padding: 0 20px;
  margin-bottom: 200px;
  max-width: 800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.home h1 {
  font-size: 3em;
  font-weight: 700;
}

.home h2 {
  font-size: 1.5em;
  font-weight: 400;
  color: #ccc;
}

.homepage-form {
  position: relative; /* Added to position search results absolutely within this container */
  margin: 20px auto 0;
  width: 100%;
}

.home-search {
  width: 100%;
  /* Removed individual box-sizing since it's now global */
  border: 1px solid rgba(255, 255, 255, 0.25);
  background-color: #242031;
  color: #D7D7D7;
  padding: 14px 16px;
  font-size: 20px;
  border-radius: 2px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  line-height: 1.25;
}

.home-search::placeholder {
  color: #D7D7D7;
}

.home-buttons {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.home-button {
  background-color: #242031;
  color: #D7D7D7;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 12px 24px;
  font-size: 18px;
  border-radius: 2px;
  margin: 0 10px;
  cursor: pointer;
}

.home-button:hover {
  background-color: #2e2a44;
}

/* Adjust search results positioning */
.search-results {
  position: absolute; /* Position absolutely within .homepage-form */
  top: 42%; /* Position right below the search bar */
  left: 0;
  width: 100%;
  background-color: #242031;
  z-index: 3; /* Ensure it appears above buttons and collage */
  border: 1px solid rgba(255, 255, 255, 0.25);
  max-height: 300px;
  overflow-y: auto;
  border-radius: 0 0 2px 2px;
}

.search-result-item {
  display: flex;
  padding: 10px;
  cursor: pointer;
}

.search-result-item:hover {
  background-color: #2e2a44;
}

.result-image {
  width: 50px;
  margin-right: 10px;
}

.result-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.result-name {
  font-weight: bold;
  color: #D7D7D7;
}

.result-type,
.result-number {
  font-size: 0.9em;
  color: #AAA;
}

/* Collage Styles */

.homepage-collage {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px; /* Increased height to display full cards on large screens */
  overflow: hidden;
  z-index: 1; /* Ensure the collage is behind other content */
}

.homepage-collage-cards {
  position: relative;
  height: 100%;
  width: 100%; /* Full width */
  margin: 0 auto;
}

.homepage-collage a {
  display: block;
  position: absolute;
  transition: transform 0.3s;
}

.homepage-collage a img {
  width: 100%;
  height: auto;
}

/* Hover effect */
.homepage-collage a:hover {
  transform: translateY(-10px);
}

/* Responsive Adjustments */

@media screen and (min-width: 765px) {
  .home h1 {
    font-size: 4em;
  }

  .home-search {
    padding: 18px 20px;
    font-size: 30px;
  }
}

@media screen and (max-width: 764px) {
  .home h1 {
    font-size: 2.5em;
  }

  .home-search {
    padding: 18px 20px;
    font-size: 24px;
  }

  .result-image {
    width: 40px;
  }

  .result-name {
    font-size: 0.9em;
  }

  /* Adjust collage styles for mobile */
  .homepage-collage {
    height: 200px; /* Adjust the height for mobile view */
  }

  /* Anchor collage cards to bottom */
  .homepage-collage a {
    bottom: 0;
    transform: translateY(0);
  }

  /* Position cards evenly from left to right */
  .homepage-collage a:nth-child(1) {
    left: 0%;
    width: 20%;
  }
  .homepage-collage a:nth-child(2) {
    left: 20%;
    width: 20%;
  }
  .homepage-collage a:nth-child(3) {
    left: 40%;
    width: 20%;
  }
  .homepage-collage a:nth-child(4) {
    left: 60%;
    width: 20%;
  }
  .homepage-collage a:nth-child(5) {
    left: 80%;
    width: 20%;
  }
}

/* Apply box-sizing globally */
*, *::before, *::after {
  box-sizing: border-box;
}