body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: linear-gradient(to bottom, #1d1c25, #131442);
  color: #333;
}

.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.App-header {
  background-color: #282c34;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 0;
}

nav ul li {
  margin: 0 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

nav ul li a:hover {
  text-decoration: underline;
}

.profile-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.profile-button.signed-in {
  background-color: #4CAF50;
}

.profile-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.sign-in-popup {
  position: absolute;
  top: 100%;
  right: 20px;
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none;
}

.sign-in-popup.visible {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.hero {
  background-color: #f0f0f0;
  padding: 40px;
  border-radius: 8px;
  margin-bottom: 40px;
}

.hero h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.cta-button, .subscribe-button, .signout-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.subscribe-button {
  background-color: #008CBA;
}

.signout-button {
  background-color: #f44336;
}

.user-actions {
  margin-bottom: 20px;
}

.features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature {
  flex-basis: 30%;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.feature h3 {
  color: #333;
}

footer {
  background-color: #282c34;
  color: white;
  padding: 20px;
  margin-top: 40px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #333;
}

.sign-in-with-apple-button {
  background: url('./assets/appleid_button@2x.png') no-repeat center center;
  background-size: contain;
  width: 210px;
  height: 40px;
  border: none;
  cursor: pointer;
  font-size: 0; /* Hide text content */
}

.sign-in-with-apple-button:hover {
  opacity: 0.9;
}

.sign-in-with-apple-button:active {
  opacity: 0.8;
}

/* Remove the following styles as they're no longer needed */
.sign-in-with-apple-button .apple-logo,
.sign-in-with-apple-button span,
#appleid-signin {
  display: none;
}

.signed-in-status {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signed-in-status p {
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.signed-in-status .signout-button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signed-in-status .signout-button:hover {
  background-color: #d32f2f;
}